<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1M"
            :label="dateLabel"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            :label="deptLabel"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="개선진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'assess-risk-register',
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentDates: [],
        deptCd: null,
        ibmStepCd: null,
      },
      tab: 'task',
      tabItems: [
        { name: 'task', icon: 'task_alt', label: '위험등록부', component: () => import(`${'./assessRiskRegisterStatus.vue'}`) },
        { name: 'rqstDept', icon: 'campaign', label: '순회점검 미개선', component: () => import(`${'./assessPatrolImprStatus.vue'}`) },
      ],
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    deptLabel() {
      let label = '';
      if (this.tab === 'task') {
        label = '평가부서'
      } else {
        label = '개선 - 조치부서'
      }
      return label;
    },
    dateLabel() {
      let label = '';
      if (this.tab === 'task') {
        label = '평가기간'
      } else {
        label = '조치완료 요청기간'
      }
      return label;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    tabClick(tab) {
      this.tab = tab
    }
  }
};
</script>